/******* screen-medium.less *******/
/*
brakpoints chosen after font-sizes, areas change at different breakpoints
*/
/******* mixins.less 2013-1-16 *******/
.clearfix {
  *zoom: 1;
}
.clearfix:before,
.clearfix:after {
  display: table;
  content: '';
}
.clearfix:after {
  clear: both;
}
/******* hide-mobile.less 2013-1-16 *******/
.cb-mobile-access,
.togglenavigation,
.body-mobile {
  display: none;
}
.cb-mobile-invisible,
.body-non-mobile {
  display: block;
}
/******* layout-medium.less 2013-1-16 *******/
#home {
  margin: 30px 0 0 5px;
  width: 26.10526316%;
  height: 29px;
}
.desk {
  width: 92.7734375%;
  max-width: 1300px;
}
.container--head {
  padding-bottom: 18px;
}
.cb-expo {
  height: 0;
  padding-bottom: 45.01953125%;
}
@media (min-width: 1554px) {
  .cb-expo {
    height: 700px;
    padding: 0;
  }
}
#head {
  margin-top: 39px;
}
.footer {
  font-size: 18px;
  font-size: 1.8rem;
  line-height: 1.22222222;
  padding: 15px 0 50px;
}
.seam.slim .link > .open,
.tel,
.vcardemail {
  font-size: 16px;
  font-size: 1.6rem;
}
.fn {
  margin-top: 22px;
}
.cbdModule--appointmentCta {
  width: auto;
  right: 60px;
  top: 175px;
}
.appointmentCta {
  width: 200px;
  height: 200px;
  font-size: 30px;
  line-height: 1.06666667;
}
.navigation {
  display: block;
  text-align: center;
  padding: 4px 0 4px;
}
.navi {
  float: none;
  display: inline-block;
}
.menu {
  line-height: 1.1;
  font-size: 13px;
  font-size: 1.3rem;
  padding: 0.55em 1.7em;
}
a.menu {
  display: block;
}
.menu:after {
  right: 1.65em;
  left: 1.65em;
}
.area {
  width: 100%;
}
.area > .unit {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.area h2,
.area .foot {
  margin-right: 2.5%;
  margin-left: 2.5%;
}
.area .part,
.area > .grid table {
  margin-right: 2.5%;
  margin-left: 2.5%;
  width: 95%;
}
.area .tiny {
  width: 45%;
}
.area > .slim {
  width: 50%;
}
.area > .slim h2,
.area > .slim .foot,
.area > .slim .part,
.area > .slim.grid table {
  margin-right: 5%;
  margin-left: 5%;
}
.area > .slim .part,
.area > .slim.grid table {
  width: 90%;
}
.area > .slim .tiny {
  width: 90%;
}
.cb-layout2 .main,
.cb-layout3 .base {
  width: 100%;
}
.cb-layout2 .main > .unit,
.cb-layout3 .base > .unit {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.cb-layout2 .main h2,
.cb-layout3 .base h2,
.cb-layout2 .main .foot,
.cb-layout3 .base .foot {
  margin-right: 0%;
  margin-left: 0%;
}
.cb-layout2 .main .part,
.cb-layout3 .base .part,
.cb-layout2 .main > .grid table,
.cb-layout3 .base > .grid table {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.cb-layout2 .main > .slim .part,
.cb-layout3 .base > .slim .part,
.cb-layout2 .main > .slim.grid table,
.cb-layout3 .base > .slim.grid table {
  width: 100%;
}
.cb-layout3 .main {
  width: 100%;
}
.cb-layout3 .main > .unit {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.cb-layout3 .main h2,
.cb-layout3 .main .foot {
  margin-right: 3.75375375%;
  margin-left: 3.75375375%;
}
.cb-layout3 .main .part,
.cb-layout3 .main > .grid table {
  margin-right: 3.75375375%;
  margin-left: 3.75375375%;
  width: 92.49249249%;
}
.cb-layout3 .main .tiny {
  width: 42.49249249%;
}
.cb-layout3 .main > .slim {
  width: 50%;
}
.cb-layout3 .main > .slim h2,
.cb-layout3 .main > .slim .foot,
.cb-layout3 .main > .slim .part,
.cb-layout3 .main > .slim.grid table {
  margin-right: 7.50750751%;
  margin-left: 7.50750751%;
}
.cb-layout3 .main > .slim .part,
.cb-layout3 .main > .slim.grid table {
  width: 84.98498498%;
}
.cb-layout3 .main > .slim .tiny {
  width: 84.98498498%;
}
.cb-layout1 .main {
  padding-bottom: 26px;
}
.side {
  width: 65.26315789%;
  margin-left: 17.36842105%;
}
.cb-layout2 .main,
.cb-layout3 .base {
  width: 65.26315789%;
  margin-left: 17.36842105%;
}
.cb-layout2 .main > .seam.wide {
  width: 153.22580645%;
  margin-left: -26.61290323%;
}
.cb-layout2 .main > .seam.wide .part {
  width: 65.26315789%;
  margin-left: 17.36842105%;
}
.cb-layout3 .main {
  width: 70.10526316%;
  margin-left: 14.94736842%;
}
.cb-layout3 .base {
  margin-top: 56px;
}
.cb-layout3 .base:empty {
  margin-top: 0;
}
.unit {
  font-size: 18px;
  line-height: 1.22222222;
}
.unit h2 {
  font-size: 14px;
  line-height: 1.14285714;
  letter-spacing: 1pt;
}
.pure:after,
.slim:after {
  width: 50px;
  margin-top: 2.1em;
}
.wide.seam:first-child {
  line-height: 1.33333333;
  padding-bottom: 40px;
  padding-bottom: 22px;
}
.cb-layout1 .slim {
  margin-bottom: 0;
}
.cb-layout1 .slim:last-child {
  margin-bottom: 38px;
}
.cb-layout1 .slim .show + .text + .text {
  margin-top: 0.4em;
}
.cb-layout1 .slim .loud {
  padding-bottom: 0.5em;
}
.cb-layout2 .loud {
  padding-bottom: 0.7em;
}
/*.seam.slim .loud*/
.loud {
  font-size: 26px;
  font-size: 2.6rem;
  line-height: 1.19230769;
}
.seam.slim {
  margin-top: 40px;
}
.seam.slim:last-child {
  margin-bottom: 116px;
}
.seam.slim .show.tiny + .text .loud {
  margin-top: 1em;
}
.seam.slim .link > .open {
  padding: 0.9em 1.4em 0.7em;
}
#disp {
  left: 50%;
  margin-left: -240px;
  width: 480px;
  font-size: 13px;
  font-size: 1.3rem;
  top: 100px !important;
}
#disp.zoom,
#disp.tube {
  left: 5%;
  margin-left: 0;
  width: 90%;
}
/*#disp.site li,
div.cb-sitemap li {
  .font-size(14); 
}*/
/*.cb-layout2 h1,
.unit h2 {
  .hyphens(none);
}*/
/*# sourceMappingURL=./screen-medium.css.map */